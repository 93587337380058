:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --map-marker-color: 000;
    /*Colors*/
    --text-color: #303030;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #39959a;
    --effect-color: #93cfd2;
    --header-text-color: var(--main-color);
    --header-bg-color: rgba(255, 255, 255, 0.85);
    --footer-bg-color: #4A4063;
    --footer-text-color: #fff;
    --menu-text-color: #a5a299;
    --menu-text-hover-color: var(--text-color);  
    /*Fonts*/
    --base-font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    --alternative-font-family: "Bree Serif", "Helvetica", "Arial", sans-serif;
}

@import "/fonts/Open Sans/all.css";
@import "/fonts/Bree Serif/all.css";

@import "/css/normalize.css";
@import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
/*@import "/css/pikaday.css";*/
/*@import "/css/animate.css";*/







.starttext h2 {
    margin-top: 1em;
}




/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/


body {
    font-family: var(--base-font-family);
    line-height: 1.5;
    font-weight: 400;
    color: var(--text-color);
    background: var(--body-bg);
    background: linear-gradient(rgba(228, 203, 175, 0.0),rgba(243, 230, 208, 0.0)), url('/thumbs/1920x1080r/theme/intendit/graphics/bg.png');
    background-repeat: repeat;

}
p {
    margin: 3px 0 5px;
}

.outerwrapper a {
    text-decoration: underline;
}


a, a:hover {
    text-decoration: none;
    color: #303030;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    display: block;
}

.imagetop {
    min-height: 50vh;
    background: no-repeat center center;
    background-size: cover;
    border-bottom: 15px solid #d6d5e3;
}

figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

/* ------------------------------- FONTS + HEADINGS + BUTTON ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    margin: 0.5em 0 0 0; 
    font-weight: 400;
}
.slick-title {
    font-size: 2.6rem;
    font-family: var(--alternative-font-family);
    font-weight: 600;
    text-align: center;
}
.button {
    margin: 1rem 0px;
    background: #e0e0e0;
    border: none;
    transition: background 1s;
}

.button:hover {
    background: #E3E3E3;
}

/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 10px);
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/* ---------------SLIDER----------- */
.slick {
    margin-bottom: 0;

}
.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;

} 
.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 73vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
    border-bottom: 15px solid #d6d5e3;
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* ----------------STARTTEXT------------  */

/* ------------------STARTBOXARNA----------- */
.startbox {
    height: 39vh;
    background-size: cover;
    background-position: center center;
}
a.boxwrapper {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    transition:background 0.5s ease-in 0s;
}
.startbox:hover a.boxwrapper {
    background: rgba(109, 106, 106, 0.3);  
    transition:background 0.5s ease-in 0s;
}
a.boxwrapper h2 {
    transition:color 0.5s ease-in 0s;
    text-align: center;
}
.startbox:hover a.boxwrapper h2 {
    color:#ffffff;
    transition:color 0.5s ease-in 0s;
}


/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}
.newsitem hr {
    color: #efefef;
}

/*------------------------------------------------------------------------------------------------ 
General content--------------------------------------------------------------------------------*/

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}


/* -------------- logo gallery ------------ */
.logotype-gallery {
    padding: 2em 0;
}
.logotype-image {
    min-height: 70px;
} 
.logotype-image div, .logotype-image a {
    text-align: center; 
    width: 100%; 
    flex:0 0 auto; 
    display: block;
} 
.logotype-image img {
    height: auto;
} 
.standing  {
    width:40%; 
} 
.squarish  {
    width:40%; 
} 
.landscape  {
    width:55%; 
}
.longlandscape {
    width:70%; 
}
.extralonglandscape {
    width:100%; 
}

/* -------------- mfp-ajax ------------ */

.mfp-ajax-holder .wrapper{
    min-height: auto;
}

.mainAjaxContent :first-child{
    margin-top: 0;
}

.mfp-ajax-holder{
    background: #FFF;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
    height: calc(100% - 80px);
    overflow-y: auto;
}

.mfp-close {
    margin: 0;
}


/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */


.scrolled header {
    background: #fff;
}

.logo a {
    font-size: 2em;
    font-weight: 400;
}
header {
    background: var(--header-bg-color);
    z-index: 10;
    width: 100%;
    position: fixed; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
    transition: background 1s ease;
}
.logo {
    padding: 10px;
}
.logo img {
    max-height: 35px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}
header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    color: #847aa0;
    font-size: 1.1em;
    letter-spacing: 1px;
    font-weight: 400;
    white-space: nowrap;    
    border-bottom: 2px solid transparent;

}
.menu.menu li a:hover, .menu.menu li.active a {
    color: #4a4063;
}

/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/
 header nav ul.menu.show,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }
/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }


}



/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: var(--footer-bg-color);
    padding: 30px 0 0px;
    text-align: center;
}
footer, footer a, footer a:hover { 
    color: var(--footer-text-color); 
}
footer svg {
    margin-right: 10px;
    fill: var(--footer-text-color);
}
footer h4 {
    margin-top:0;
}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: var(--footer-bg-color);
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #564c6d;
    line-height:1.1;
}
p.intenditfooter {
    margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */

.boltform form { 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
}

.boltforms-row {
    flex-basis: calc(100% - 20px);
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: content-box;
}

.boltforms-six-row {
    flex-basis: calc(50% - 20px);
}

.boltforms-four-row {
    flex-basis: calc((100% / 3) - 20px);
}

.boltforms-three-row {
    flex-basis: calc(25% - 20px);
}

.boltform textarea, .boltform input {
    width: 100%;
}

@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }

    .boltform form {
        flex-direction: column;
    }
}

.formwrapper {
    background-color: #d6d5e3; 
    padding: 40px 0px;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #bebdd0;
    border: none;
    width: 100%;
    transition: background 0.5s ease;
}

.boltforms-row button:hover {
    background: #aaa9bf;
    color: #fff;

}

.boltforms-row label {
   display: none !important;
}


.boltform textarea, .boltform input {
    width: 100%;
    background: rgba(255, 255, 255, 0.11);
    border: 0px;

}


.contactform h2 {
    padding-left: 10px;
    margin-bottom: 0.5em;
}


::-webkit-input-placeholder { color: #4a4063; }
::-moz-placeholder { color: #4a4063; } /* firefox 19+ */
:-ms-input-placeholder { color: #4a4063; } /* ie */
input:-moz-placeholder { color: #4a4063; }

/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 35vh;
}

.map-canvas img {
    max-width: none;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under mobilmeny----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    .menu .dropdown ul {
        border-left: 3px solid #4a4063;
    }

}

/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 

    .formwrapper {
        margin-top: 1vh;
    }
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

    .container {
        width: 96%; 
    } 


}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    .container.sml {
        width: 96%; 
    } 

    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 

}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logo img {
        max-height: 17px;
    }
    footer {    
        padding: 20px 10px;
    }

}
